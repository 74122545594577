<template>
  <b-card
    title="Edit Volunteer"
  >
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- form -->

    <b-form
      ref="form"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="NIJ"
            label-for="nij"
          >
            <b-form-input
              id="nij"
              v-model="nij"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="name"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Phone"
            label-for="cellphone"
          >
            <b-form-input
              id="cellphone"
              v-model="cellphone"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Church"
            label-for="church"
          >
            <b-form-input
              id="church"
              v-model="church"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="CG"
            label-for="cg"
          >
            <b-form-input
              id="cg"
              v-model="cg"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="CG Presence Percentage"
            label-for="cg_presence"
          >
            <span v-if="presenceResult === '-'">
              -
            </span>
            <span v-else>
              <span
                v-for="item in presenceResult"
                :key="item"
              >
                <span
                  v-if="item === '-'"
                  style="margin-right: 5px;"
                >
                  -
                </span>
                <b-badge
                  v-else
                  :class="cgPresenceResult(Number(item))"
                  style="margin-right: 5px;"
                >
                  {{ Math.round(Number(item)) }}%
                </b-badge>
              </span>
            </span>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Ministry"
            label-for="ministry"
          >
            <b-form-input
              id="ministry"
              v-model="ministry"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Division"
            label-for="division"
          >
            <b-form-input
              id="division"
              v-model="division"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Ministry Position"
            label-for="position"
          >
            <b-form-input
              id="position"
              v-model="position"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Status"
            label-for="status"
          >
            <b-badge
              v-if="status === 0"
              variant="danger"
            >
              Need rereg
            </b-badge>
            <b-badge
              v-else-if="status === 1"
              variant="primary"
            >
              Need verification for reregistration
            </b-badge>
            <b-badge
              v-else-if="status === 2"
              variant="success"
            >
              Active
            </b-badge>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="getBack()"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BBadge,

    Loading,
  },
  directives: {
      Ripple,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      nij: '',
      name: '',
      cellphone: '',
      church: '',
      cg: '',
      ministry: '',
      division: '',
      position: '',
      presenceResult: '',
      status: '',
    }
  },
  mounted() {
    // Get the volunteer ldata
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/volunteer/view/${this.$route.params.id}`)
      .then(volunteer => {
        this.isLoading = false

        this.nij = volunteer.data.nij
        this.name = volunteer.data.name
        this.cellphone = volunteer.data.cell_phone
        this.church = volunteer.data.church
        this.cg = volunteer.data.cg
        this.ministry = volunteer.data.ministry
        this.division = volunteer.data.division
        this.position = volunteer.data.position
        this.presenceResult = volunteer.data.attend_percentage.split(',')
        this.status = volunteer.data.status
      })
  },
  methods: {
    cgPresenceResult(result) {
      let badge = 'badge-danger'
      if (result >= 81.25) {
        badge = 'badge-success'
      } else if (result >= 56.25) {
        badge = 'badge-info'
      } else if (result >= 31.25) {
        badge = 'badge-warning'
      }

      return badge
    },
    getBack() {
      this.$router.go(-1)
    },
  },
}
</script>